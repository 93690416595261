import React from "react"

import { StaticImage } from "gatsby-plugin-image";
import Radium, { StyleRoot } from "radium";
import * as s from "../styles/website";
import {copyrightDesktopMediaQuery, h1Smaller, presentationLongMediaQuery} from "../styles/website";
import NavigationMenu from "./NavigationMenu";
import Copyright from "./Copyright";
import KevinsPicture from "./KevinsPicture";
import PresentationLong from "./PresentationLong";
import PresentationShort from "./PresentationShort";
import GoogleAnalytics from "./GoogleAnalytics";

const WebSiteBlogPost = (props) => {
    return (
        <>
            <GoogleAnalytics />
            <div style={s.wrapper}>
                <header style={s.header}>
                    <div style={s.innerPadding}>
                        <div style={{overflow: "hidden"}}>
                            <div style={s.headerColumnBlogPost}>
                                <KevinsPicture />
                                <div style={s.presentationLongMediaQuery}>
                                    <PresentationLong />
                                </div>
                                <div style={s.presentationShortMediaQuery}>
                                    <PresentationShort />
                                </div>
                            </div>
                            <div style={s.headerColumnBlogPost}>
                                <NavigationMenu />
                                <div style={s.copyrightDesktopMediaQuery}>
                                    <Copyright />
                                </div>
                            </div>
                            <div style={s.copyrightMobileMediaQuery}>
                                <Copyright />
                            </div>
                        </div>
                    </div>
                </header>
                <main style={s.main}>
                    <div style={s.innerPadding}>
                        {props.children}
                    </div>
                </main>
            </div>
        </>
    )
}

export default Radium(WebSiteBlogPost);
