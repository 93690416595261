import React from "react"

import SiteConfig from '../../site-config';

import { StyleRoot } from 'radium';
import {graphql} from "gatsby";
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import { INLINES } from '@contentful/rich-text-types'

import Head from "./Head";
import WebSiteBlogPost from "./WebSiteBlogPost";
import * as s from '../styles/website'


const BlogPostPage = ({data}) => {
    const post = data.contentfulBlogPost;
    post.createdAt = post.createdAt.split('T')[0];

    const options = {
        renderNode: {
            [INLINES.HYPERLINK]: (node, text) => {
                const target = node.data.uri.startsWith(SiteConfig.siteUrl) ? 'self' : '_blank';
                return (<a href={node.data.uri} target={target}>{text}</a>);
            }
        }
    };

    return (
      <StyleRoot style={{height: '100%'}}>
        <Head 
          title={post.title} 
          description={post.shortDescription}
          siteUrl={SiteConfig.siteUrl + '/' + post.slug}
          />
        <WebSiteBlogPost>
          <article style={s.homepageArticleWrapper}>
            <p style={s.homepageArticleDateTime}>{post.createdAt}</p>
            <h1>{post.title}</h1>
            {renderRichText(post.content, options)}
          </article>
        </WebSiteBlogPost>
      </StyleRoot>
    );
};

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: {eq: $slug}) {
      slug
      title
      shortDescription
      createdAt
      content {
        raw
      }
    }
  }
`

export default BlogPostPage;
