import React from "react";
import * as s from "../styles/website";

const PresentationShort = () => {
    return (
        <h2 style={s.h1}>
            Kevin's Blog
        </h2>
    );
}

export default PresentationShort;
